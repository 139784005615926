import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button, Drawer, DrawerControls, DrawerHeader, DrawerTitle } from '@meterup/metric';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchFloorPlan } from '../../../../api/devicesApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { UploadFloorPlanDialog } from '../../../../components/Device/UploadFloorPlanDialog';
import { PanAndZoomRegion } from '../../../../components/FloorPlan/PanAndZoomRegion';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { shadows, styled } from '../../../../stitches';
import { checkDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../../utils/isDefined';
import { preloadImage } from '../../../../utils/preloadImage';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/floorplans',
});

const FloorPlanDrawer = styled(Drawer, {
  variants: {
    isFullScreen: {
      true: { maxWidth: '100%' },
      false: {
        '@sm': {
          maxWidth: '50%',
        },
      },
    },
  },
});

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  boxShadow: shadows.fenceAllLight,
  padding: 0.5,
});

export default function FloorPlanDetail() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.FloorPlanDetail),
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const floorPlanURL = useQuery(
    ['controller', controllerName, 'floor-plan'],
    async () => {
      const url = await fetchFloorPlan(controllerName);
      return url ? preloadImage(url) : null;
    },
    { suspense: true },
  ).data;

  const downloadFloorPlan = (): void => {
    const filename = `${controllerName}-floor-plan-${new Date()
      .toLocaleDateString()
      .replace(/\//g, '-')}`;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(floorPlanURL, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = floorPlanURL || '';
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };

  return (
    <FloorPlanDrawer isFullScreen={isFullScreen}>
      <DrawerHeader>
        <Button
          arrangement="hidden-label"
          icon={isFullScreen ? 'drawerClose' : 'drawerOpen'}
          variant="tertiary"
          onClick={() => setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen)}
        >
          View full screen
        </Button>

        <DrawerTitle>Floor plan</DrawerTitle>
        <DrawerControls>
          <UploadFloorPlanDialog
            controllerName={controllerName}
            trigger={
              <Button arrangement="leading-icon" icon="floorplan" variant="tertiary">
                Replace floor plan
              </Button>
            }
          />
          <Button
            arrangement="leading-icon"
            icon="download"
            variant="tertiary"
            onClick={() => downloadFloorPlan()}
          >
            Download
          </Button>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <PanningContainer>
        <PanAndZoomRegion>
          {isDefined(floorPlanURL) && (
            <img
              src={floorPlanURL}
              alt="Floor plan"
              style={{ maxHeight: '50vh', userSelect: 'none' }}
            />
          )}
        </PanAndZoomRegion>
      </PanningContainer>
    </FloorPlanDrawer>
  );
}
