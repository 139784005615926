import React from 'react';

import { SidebarGroupContainerInternal } from './SidebarGroupContainerInternal';

export interface SidebarGroupProps {}

export const SidebarGroup: React.FunctionComponent<SidebarGroupProps> = ({ children }) => (
  <SidebarGroupContainerInternal>{children}</SidebarGroupContainerInternal>
);

export default SidebarGroup;
