import React from 'react';

import type { BandVariant } from '../Band/Band';
import type { HardwareIconName } from '../HardwareIcon/HardwareIcon';
import { styled } from '../../stitches.config';
import Band from '../Band/Band';
import HardwareIcon from '../HardwareIcon/HardwareIcon';

const Base = styled('div', {
  display: 'inline-flex',
  position: 'relative',
});

const CenteredItem = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  inset: 0,
});

export interface BandWithIconProps {
  /**
   * Name of the hardware component
   */
  icon: HardwareIconName;
  /**
   * Size of the icon
   */
  size?: 'large' | 'medium' | 'small';
  /**
   * Color variant applied to the band around the icon
   */
  variant?: BandVariant;
}

const BandWithIcon = ({ icon, size = 'medium', variant = 'alternative' }: BandWithIconProps) => (
  <Base>
    <Band size={size} variant={variant} />
    <CenteredItem>
      <HardwareIcon icon={icon} size={size} />
    </CenteredItem>
  </Base>
);

export default BandWithIcon;
