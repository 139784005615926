import type { AdhocControllerEvent } from '@meterup/proto/esm/api';
import type { CellProps, Column } from 'react-table';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchControllerEvent } from '../../../api/controllersApi';
import { useCurrentTimezone } from '../../../providers/CurrentTimezoneProvider';
import { AutoTable } from '../../AutoTable/AutoTable';
import { TimestampWithTimezone, TimezoneAbbreviation } from '../../timestamps';

interface EventLogsNetworkWidgetProps {
  controllerName: string;
}

const EventLogsNetworkWidget = ({ controllerName }: EventLogsNetworkWidgetProps) => {
  const timezone = useCurrentTimezone();
  const tz = TimezoneAbbreviation(timezone);
  const logsData =
    useQuery(
      ['controller', controllerName, 'logs'],
      async () => fetchControllerEvent(controllerName),
      {
        suspense: true,
        refetchInterval: 1000,
      },
    ).data ?? [];

  const columns: Column<AdhocControllerEvent>[] = useMemo(
    () => [
      {
        Header: 'Event',
        accessor: (e) => e.event_type,
      },
      {
        Header: 'Message',
        accessor: (e) => e.message,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<AdhocControllerEvent, string>) => (
          <div style={{ whiteSpace: 'normal' }}>{e.value}</div>
        ),
      },
      {
        Header: `Time (${tz})`,
        accessor: (e) => e.created_at,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<AdhocControllerEvent, string>) => (
          <div>
            <TimestampWithTimezone value={e.value} timezone={timezone} />
          </div>
        ),
      },
    ],
    [timezone, tz],
  );

  return <AutoTable columns={columns} data={logsData} />;
};

export default EventLogsNetworkWidget;
