import React from 'react';

import type { IconName } from '../../../assets/Icon/Icon';
import Icon from '../../../assets/Icon/Icon';
import { colors, darkThemeSelector, shadows, styled } from '../../../stitches.config';

export type CapsuleContainerArrangement = 'leading-icon' | 'leading-label';

export type CapsuleContainerProps = {
  /**
   * Description of the action taken when clicking the component. **Required** for accessibility support.
   */
  'aria-label': string;
  /**
   * Set the content's order and visibilty.
   */
  arrangement: CapsuleContainerArrangement;
  /**
   * Displayed as the contents of the component.
   */
  children: React.ReactNode;
  /**
   * Set which icon to display for the action.
   */
  icon: IconName;
  /**
   * Controls whether the capsule should show a clicked state.
   */
  isClicked?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const CapsuleContainerBox = styled('button', {
  all: 'inherit',
  cursor: 'pointer',
  $$iconColor: colors['gray-400'],
  $$suppliedIconOpacity: 0,
  $$clickedIconOpacity: 0,
  flex: '0 0 auto',
  hStack: '$6',
  display: 'inline-flex',
  borderRadius: '$4',
  paddingX: '$8',
  transition: 'all 150ms ease-out',
  '&:hover': {
    boxShadow: shadows.fenceAllLight,
  },
  '&:focus': {
    boxShadow: shadows.fenceFocusedLight,
    outline: 'none',
  },
  [darkThemeSelector]: {
    '&:hover': {
      boxShadow: shadows.fenceAllDark,
    },
    '&:focus, &:focus-within': {
      boxShadow: shadows.fenceFocusedDark,
    },
  },
  variants: {
    arrangement: {
      'leading-icon': {
        // Pulls the contents back into alignment with the original position of the text.
        marginRight: '-8px',
        flexDirection: 'row-reverse',
      },
      'leading-label': {
        // Pulls the contents back into alignment with the original position of the text.
        marginLeft: '-8px',
        flexDirection: 'row',
      },
    },
    isClicked: {
      true: {
        $$suppliedIconOpacity: 0,
        $$clickedIconOpacity: 1,
        $$iconColor: colors['brand-400'],
        color: colors['brand-700'],
        backgroundColor: colors['brand-50'],
        boxShadow: shadows.fenceAllLight,
        [darkThemeSelector]: {
          $$iconColor: colors['brand-300'],
          color: colors['brand-50'],
          backgroundColor: colors['brand-900'],
          boxShadow: shadows.fenceAllDark,
        },
      },
      false: {
        '&:hover, &:focus': {
          $$suppliedIconOpacity: 1,
        },
        [darkThemeSelector]: {
          $$iconColor: colors['gray-200'],
          '&:focus, &:focus-within': {
            $$suppliedIconOpacity: 1,
          },
        },
      },
    },
  },
});

const IconContainer = styled('div', {
  position: 'relative',
  display: 'inline-flex',
});

const StyledIcon = styled(Icon, {
  opacity: '$$suppliedIconOpacity',
  color: '$$iconColor',
  width: '$12',
  height: '$12',
  willChange: 'opacity, color',
  transition: 'all 150ms ease-out',
  [darkThemeSelector]: {
    color: '$$iconColor',
  },
});

const ClickedCheckmarkIcon = styled(StyledIcon, {
  opacity: '$$clickedIconOpacity',
  position: 'absolute',
  inset: 0,
});

export const CapsuleContainer = React.forwardRef<HTMLButtonElement, CapsuleContainerProps>(
  ({ arrangement = 'leading-label', isClicked, children, icon, ...props }, ref) => (
    <CapsuleContainerBox {...props} ref={ref} arrangement={arrangement} isClicked={isClicked}>
      {children}
      <IconContainer>
        <StyledIcon icon={icon} />
        <ClickedCheckmarkIcon icon="checkmarkCircle" />
      </IconContainer>
    </CapsuleContainerBox>
  ),
);

export default CapsuleContainer;
