import React, { createContext, useContext, useMemo } from 'react';

import { PACIFIC_TIMEZONE } from '../components/timestamps';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { isDefined } from '../utils/isDefined';

interface CurrentTimezoneData {
  currentTimezone: string;
  changeCurrentTimezone: (zone: string) => void;
}

export const CurrentTimezoneContext = createContext<CurrentTimezoneData>({} as any);

export const CurrentTimezoneProvider: React.FC = ({ children }) => {
  const [currentTimezone, setCurrentTimezone] = useLocalStorage<string>(
    'currentTimezone',
    PACIFIC_TIMEZONE,
  );

  const value = useMemo(
    () =>
      isDefined(currentTimezone)
        ? { currentTimezone, changeCurrentTimezone: setCurrentTimezone }
        : null,
    [currentTimezone, setCurrentTimezone],
  );

  return value ? (
    <CurrentTimezoneContext.Provider value={value}>{children}</CurrentTimezoneContext.Provider>
  ) : null;
};

export const useCurrentTimezone = () => useContext(CurrentTimezoneContext).currentTimezone;

export const useChangeCurrentTimezoneCallback = () =>
  useContext(CurrentTimezoneContext).changeCurrentTimezone;
