import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import { List, ListItemHeader, ListTitle } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClientsJSON } from '../api/clientsApi';
import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { isGuest, isOnline, isWired, isWireless } from '../utils/clients';
import { isDefined } from '../utils/isDefined';
import { makeLink } from '../utils/makeLink';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { ListItemViewAllLink } from './ListItemViewAllLink';
import { Nav } from './Nav';
import { NeutralBadge, PositiveBadge, SignalStrengthBadge, WiredBadge } from './Network/badges';
import { ListItemStatList, StatItem, StatItemLabel } from './StatItemLabel';

const columns: Column<api.ControllerClient>[] = [
  {
    Header: 'Name',
    accessor: (d) => d.lease?.name,
  },
  {
    Header: 'Signal',
    accessor: (d) => (isWireless(d) ? d.signal : null),
    Cell: (props: CellProps<api.ControllerClient, number | null>) =>
      isWired(props.row.original) ? <WiredBadge /> : <SignalStrengthBadge value={props.value} />,
  },
];

export const ClientsWidget = ({ controllerName }: { controllerName: string }) => {
  const clients =
    useQuery(
      ['controller', controllerName, 'clients'],
      async () => fetchClientsJSON(controllerName),
      { suspense: true },
    ).data ?? [];

  const limitedClients = clients.slice(0, 5);

  const onlineCount = clients.filter(isOnline).length;
  const guestCount = clients.filter(isGuest).length;
  const wiredCount = clients.filter(isWired).length;

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Clients</ListTitle>
      </ListItemHeader>
      <ListItemStatList>
        <StatItem>
          <PositiveBadge size="large" icon="client" arrangement="leading-icon">
            {onlineCount}
          </PositiveBadge>
          <StatItemLabel>Online</StatItemLabel>
        </StatItem>
        <StatItem>
          <NeutralBadge size="large" icon="client" arrangement="leading-icon">
            {guestCount}
          </NeutralBadge>
          <StatItemLabel>Guest</StatItemLabel>
        </StatItem>
        <StatItem>
          <NeutralBadge size="large" icon="wired" arrangement="leading-icon">
            {wiredCount}
          </NeutralBadge>
          <StatItemLabel>Wired</StatItemLabel>
        </StatItem>
      </ListItemStatList>
      <ListItemTableContainer>
        <AutoTable
          columns={columns}
          data={limitedClients}
          shouldShowTopBar={false}
          onRowDeselect={closeDrawer}
          isRowSelected={(row) => isDefined(drawerParams) && row.lease?.mac === drawerParams.mac}
          linkProps={(row) => ({
            to: Nav.makeTo({
              drawer: makeLink(paths.drawers.ClientDetail, {
                controllerName,
                // TODO: Determine if lease is ever undefined?
                mac: row.lease?.mac ?? '',
              }),
            }),
          })}
        />
      </ListItemTableContainer>
      <ListItemViewAllLink to={makeLink(paths.pages.ControllerClientsList, { controllerName })}>
        View all{' '}
        {limitedClients.length < clients.length
          ? `(Showing ${limitedClients.length} out of ${clients.length})`
          : ''}
      </ListItemViewAllLink>
    </List>
  );
};
