import * as d3 from 'd3';

export const palette = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  'fence-light': 'rgba(215, 216, 219, 1)',
  'fence-dark': 'rgba(18, 20, 28, 1)',
  'brand-50': '#EFF1FF',
  'brand-100': '#D8DCFF',
  'brand-200': '#C9CFFF',
  'brand-300': '#A4AEFA',
  'brand-400': '#8A96F4',
  'brand-500': '#6977E1',
  'brand-600': '#5461C8',
  'brand-700': '#4551AF',
  'brand-800': '#384084',
  'brand-900': '#2B305B',
  'blue-50': '#EDF4FF',
  'blue-100': '#C0D7FD',
  'blue-200': '#92BBFC',
  'blue-300': '#6AA2F6',
  'blue-400': '#528FEB',
  'blue-500': '#4280DE',
  'blue-600': '#316CC6',
  'blue-700': '#1754B1',
  'blue-800': '#124A9F',
  'blue-900': '#073376',
  'gray-50': '#FAFAFC',
  'gray-100': '#E4E6F0',
  'gray-200': '#C5C8D6',
  'gray-300': '#9799AD',
  'gray-400': '#818394',
  'gray-500': '#66687A',
  'gray-600': '#4E5161',
  'gray-700': '#343647',
  'gray-800': '#1E202E',
  'gray-900': '#090B14',
  'green-50': '#D5FADC',
  'green-100': '#A7FAB5',
  'green-200': '#7CFA91',
  'green-300': '#5CEB74',
  'green-400': '#58DB6D',
  'green-500': '#33C34A',
  'green-600': '#1CA733',
  'green-700': '#0F8922',
  'green-800': '#056F15',
  'green-900': '#01570F',
  'red-50': '#FFE8E8',
  'red-100': '#FFC7C7',
  'red-200': '#FC8282',
  'red-300': '#F45757',
  'red-400': '#E33131',
  'red-500': '#D42121',
  'red-600': '#BB0F0F',
  'red-700': '#9E0707',
  'red-800': '#8A0909',
  'red-900': '#750505',
};

export type ColorPalette = keyof typeof palette;

/**
 * Sets the opacity of the given CSS color specifier string and returns the new color formatted as an rgba() string.
 * @param specifier
 * @param opacity
 */
export const fade = (specifier: string, opacity: number): string => {
  const c = d3.color(specifier);

  if (c) {
    c.opacity = opacity;
    return c.formatRgb();
  }

  return 'rgba(0,0,0,0)';
};
