import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { SidebarNavItemInternalProps } from '../Sidebars/SidebarNavItemInternal';
import { SidebarNavItemInternal } from '../Sidebars/SidebarNavItemInternal';

export interface MobileSidebarNavItemProps extends SidebarNavItemInternalProps {}

const MobileSidebarNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    { size = 'large', ...props }: PolymorphicComponentProps<Tag, MobileSidebarNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => <SidebarNavItemInternal ref={forwardedRef} size={size} {...props} />,
) as Polymorphic.ForwardRefComponent<React.ElementType, MobileSidebarNavItemProps>;

export default MobileSidebarNavItem;
