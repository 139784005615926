import { formatDistanceToNow, isAfter, isValid, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { DateTime } from 'luxon';
import React from 'react';

import { isDefinedAndNotEmpty } from '../utils/isDefined';

export const PACIFIC_TIMEZONE = 'America/Los_Angeles';
export const MOUNTAIN_TIMEZONE = 'America/Denver';
export const CENTRAL_TIMEZONE = 'America/Chicago';
export const EASTERN_TIMEZONE = 'America/New_York';
export const UTC_TIMEZONE = 'UTC';

export const timezones = [
  PACIFIC_TIMEZONE,
  MOUNTAIN_TIMEZONE,
  CENTRAL_TIMEZONE,
  EASTERN_TIMEZONE,
  UTC_TIMEZONE,
];

export const isTimestampKnown = (value: string | null | undefined): value is string => {
  if (isDefinedAndNotEmpty(value)) {
    const date = new Date(value);
    return isValid(date) && isAfter(date, new Date(2010, 1, 1));
  }

  return false;
};

interface DistanceToNowTimestampProps {
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  addSuffix?: boolean;
}

export const DistanceToNowTimestamp = ({
  value,
  addSuffix = true,
}: DistanceToNowTimestampProps) => (
  <>{formatDistanceToNow(parseISO(value), { locale: enUS, addSuffix })}</>
);

export const DistanceToNowTimestampOrNull = (props: DistanceToNowTimestampProps) =>
  isTimestampKnown(props.value) ? <DistanceToNowTimestamp value={props.value} /> : null;

export const TimezoneAbbreviation = (timezone: string) =>
  DateTime.local().setZone(timezone).offsetNameShort;

export const CurrentLocalTime = () => DateTime.local();

export const StringToDateTime = (value: string) => DateTime.fromISO(value);

export const TimestampWithTimezone = ({ value, timezone }: { value: string; timezone: string }) => (
  <>{DateTime.fromISO(value).setZone(timezone).toLocaleString(DateTime.DATETIME_MED)}</>
);
