import { darkThemeSelector, shadows, styled } from '../../stitches.config';

export const SidebarGroupContainerInternal = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
  padding: '$12',
  boxShadow: shadows.fenceTopLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceTopDark,
  },
});
