import type { ReactNode } from 'react';
import React from 'react';

import { styled } from '../../stitches.config';
import { SidebarGroupContainerInternal } from '../Sidebars/SidebarGroupContainerInternal';

const Container = styled(SidebarGroupContainerInternal, {
  height: '$56',
  justifyContent: 'center',
  flexShrink: 0,
});

export interface DesktopSidebarLocationControlGroupProps {
  children: ReactNode;
}

export const DesktopSidebarLocationControlGroup = ({
  children,
}: DesktopSidebarLocationControlGroupProps) => <Container>{children}</Container>;

export default DesktopSidebarLocationControlGroup;
