import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Drawer, DrawerContent, DrawerControls, DrawerHeader, DrawerTitle } from '@meterup/metric';
import React from 'react';

import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import IncidentDetailContent from '../../../../components/Incidents/IncidentDetailContent';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ResourceNotFoundError } from '../../../../errors/errors';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { useControllerIncident } from './common/useControllerIncident';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents/:id',
});

export default function IncidentDetail() {
  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.IncidentDetail),
  );

  const incident = useControllerIncident(controllerName, id);

  expectDefinedOrThrow(incident, new ResourceNotFoundError('Incident not found'));

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Incident details</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>

      <DrawerContent>
        <IncidentDetailContent controllerName={controllerName} incident={incident} />
      </DrawerContent>
    </Drawer>
  );
}
