import type { AxiosInstance } from 'axios';

import type { FixMeLater } from '../utils/FixMeLater';

type ExtractAsObject<T> = T extends { toObject(...args: any[]): infer U } ? U : never;

interface BaseMessageConstructor {
  deserializeBinary(bytes: Uint8Array): any;
  toObject(includeInstance: boolean, message: any): any;
}

interface BaseMessage {
  serializeBinary(): Uint8Array;
}

interface ResultSuccess {
  success: true;
}

interface ResultError {
  success: false;
  error?: string;
}

type Result = ResultSuccess | ResultError;

/**
 * Creates a client that deals with old style protobuf messages generated by
 * protoc.
 */
export function createLegacyProtoClient(axiosInstance: AxiosInstance) {
  return {
    async get<ResponseMessageType extends BaseMessageConstructor>(
      Message: ResponseMessageType,
      url: string,
      params?: object,
    ): Promise<ExtractAsObject<ResponseMessageType>> {
      const response = await axiosInstance.get(url, {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/x-protobuf' },
        // KLUDGE: This `bust` query param is a workaround for a bug that seems
        // to affect Safari where proto requests are maybe de-duped with JSON
        // requests that look like the same URL.
        params: { ...params, bust: new Date().getTime() },
      });
      const decodedResponseData = Message.deserializeBinary(new Uint8Array(response.data));
      return Message.toObject(false, decodedResponseData);
    },
    async post(url: string, payload: BaseMessage): Promise<Result> {
      try {
        await axiosInstance.post(url, payload.serializeBinary(), {
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/vnd.google.protobuf; charset=utf-8',
          },
          responseType: 'json',
        });
        return { success: true };
      } catch (error) {
        return { success: false, error: (error as FixMeLater).response?.data?.title };
      }
    },
    async put(url: string, payload: BaseMessage): Promise<Result> {
      try {
        await axiosInstance.put(url, payload.serializeBinary(), {
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/vnd.google.protobuf; charset=utf-8',
          },
          responseType: 'json',
        });
        return { success: true };
      } catch (error) {
        return { success: false, error: (error as FixMeLater).response?.data?.title };
      }
    },
    async delete(url: string, errorMessage: string, payload?: BaseMessage): Promise<Result> {
      try {
        await axiosInstance.delete(url, {
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/vnd.google.protobuf; charset=utf-8',
          },
          responseType: 'json',
          data: payload?.serializeBinary(),
        });
        return { success: true };
      } catch {
        return { success: false, error: errorMessage };
      }
    },
  };
}
