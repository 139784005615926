import { Button, HStack, space } from '@meterup/metric';
import React from 'react';
import { useId } from 'react-aria';

import { isDefined } from '../../utils/isDefined';
import { NeutralBadge } from '../Network/badges';

export interface FilePickerInputProps {
  accept: string;
  value: File | null;
  onChange: (file: File | null) => void;
}

export const FilePickerInput = ({ accept, value, onChange }: FilePickerInputProps) => {
  const id = useId();
  return (
    <HStack align="center" spacing={space(8)}>
      <Button as="label" htmlFor={id} variant="secondary">
        Choose file
      </Button>
      <input
        id={id}
        type="file"
        accept={accept}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            onChange(file);
          }
        }}
      />
      {isDefined(value) && (
        <NeutralBadge ends="card" size="large">
          <span
            style={{
              display: 'block',
              minWidth: 0,
              maxWidth: 200,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value.name}
          </span>
        </NeutralBadge>
      )}
    </HStack>
  );
};
