import type { api } from '@meterup/proto';
import type { Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button } from '@meterup/metric';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import type { TabFilter } from '../../../components/AutoTable/TabFilters';
import { fetchCompaniesJSON } from '../../../api/companyApi';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { TabFilters } from '../../../components/AutoTable/TabFilters';
import { Nav } from '../../../components/Nav';
import { Page, PageHeader, PageTitle } from '../../../components/Page';
import { paths } from '../../../constants';
import { makeDrawerLink } from '../../../utils/makeLink';

enum FilterStrategy {
  All = 'All',
  HasController = 'HasController',
}

const columns: Column<api.CompanyResponse>[] = [
  {
    Header: 'Company name',
    accessor: (row) => row.name,
  },
  {
    Header: 'Slug',
    accessor: (row) => row.slug,
  },
];

export const Meta: PagefileMetaFn = () => ({
  path: '/companies',
});

export default function CompaniesList() {
  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(
    FilterStrategy.HasController,
  );

  const companies =
    useQuery(['companies', 'filter', filterStrategy], () =>
      fetchCompaniesJSON(
        match(filterStrategy)
          .with(FilterStrategy.HasController, () => true)
          .with(FilterStrategy.All, () => false)
          .exhaustive(),
      ),
    ).data ?? [];

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
    },
    {
      key: FilterStrategy.HasController,
      label: 'Has controller',
    },
  ];

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.CompanySummary);

  return (
    <Page>
      <PageHeader>
        <PageTitle>Companies</PageTitle>
      </PageHeader>
      <AutoTable
        columns={columns}
        data={companies}
        isRowSelected={(row) => row.slug === drawerParams?.companyName}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.CompanySummary, { companyName: row.slug }),
        })}
        tabs={
          <TabFilters
            filters={filters}
            activeFilterKey={filterStrategy}
            onActivateFilter={(f) => setFilterStrategy(f.key)}
          />
        }
        additionalControls={
          <Button
            as={Link}
            to={makeDrawerLink(paths.drawers.CreateCompany, {})}
            arrangement="leading-icon"
            icon="plusCircle"
            variant="tertiary"
          >
            Create
          </Button>
        }
      />
    </Page>
  );
}
