import type { CellProps, Column } from 'react-table';
import { List, ListItemHeader, ListTitle } from '@meterup/metric';
import React from 'react';

import type { MeterControllerConfig } from '../models/MeterControllerConfig';
import type { MeterV2WirelessServiceSet } from '../models/MeterV2WirelessServiceSet';
import { paths } from '../constants';
import { useConfigEditor } from '../context/ConfigEditorContext';
import { styled } from '../stitches';
import { makeDrawerLink } from '../utils/makeLink';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { Nav } from './Nav';
import { NeutralBadge, PositiveBadge } from './Network/badges';

const BadgeList = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  flexWrap: 'wrap',
});

function createColumns(config: MeterControllerConfig) {
  const columns: Column<MeterV2WirelessServiceSet>[] = [
    {
      Header: 'SSID',
      accessor: (d) => d.ssid,
    },
    {
      Header: 'Visibility',
      accessor: (d) => (d.hidden ? 'Hidden' : 'Visible'),
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) =>
        props.row.original.hidden ? (
          <NeutralBadge icon="eyeClosed" arrangement="hidden-label">
            Hidden
          </NeutralBadge>
        ) : (
          <PositiveBadge icon="eyeOpen" arrangement="hidden-label">
            Visible
          </PositiveBadge>
        ),
    },
    {
      Header: 'Access points',
      accessor: (d) =>
        `${config.getAPsBroadcastingServiceSet(d).length} / ${config.accessPoints.length}`,
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) => (
        <NeutralBadge icon="device" arrangement="leading-icon">
          {props.value}
        </NeutralBadge>
      ),
    },
    {
      Header: 'Bands',
      accessor: (d) => d.getSortedBands().join(', '),
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) => (
        <BadgeList>
          {props.row.original.getSortedBands().map((b) => (
            <NeutralBadge key={b} ends="card">
              {b}
            </NeutralBadge>
          ))}
        </BadgeList>
      ),
    },
  ];
  return columns;
}

export const ServiceSetsWidgetCOS = ({ controllerName }: { controllerName: string }) => {
  const config = useConfigEditor().draftModel;

  const columns = createColumns(config);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SSIDEdit);

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Service sets </ListTitle>
        <div style={{ marginLeft: 'auto' }}>
          <NeutralBadge ends="card" variant="brand">
            COS
          </NeutralBadge>
        </div>
      </ListItemHeader>
      <ListItemTableContainer>
        <AutoTable
          columns={columns}
          data={config.serviceSets}
          shouldShowTopBar={false}
          isRowSelected={(row) => drawerParams?.eid === row.ephemeralId}
          linkProps={(row) => ({
            to: makeDrawerLink(paths.drawers.SSIDEdit, {
              controllerName,
              eid: row.ephemeralId,
            }),
          })}
        />
      </ListItemTableContainer>
    </List>
  );
};
