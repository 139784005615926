import {
  DesktopSidebar,
  DesktopSidebarNavItem,
  MobileSidebar,
  MobileSidebarNavItem,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarGroup,
} from '@meterup/metric';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { paths } from '../../constants';
import { useIsPathActiveFn } from '../../hooks/useIsPathActiveFn';
import { makeLink } from '../../utils/makeLink';
import { Nav } from '../Nav';
import { MobileSidebarModal } from './MobileSidebarModal';

export const NOCDesktopSidebar = () => {
  const isPathActive = useIsPathActiveFn();
  const location = Nav.useRegionLocation('root');

  return (
    <DesktopSidebar>
      <SidebarGroup>
        <DesktopSidebarNavItem
          as={Link}
          to={makeLink(paths.pages.ControllersList, {})}
          label="Controllers"
          isSelected={location.pathname === '/' || isPathActive(paths.pages.ControllerDetails)}
          icon="controller"
        />
        <DesktopSidebarNavItem
          as={Link}
          to={makeLink(paths.pages.CompaniesList, {})}
          label="Companies"
          isSelected={isPathActive(paths.pages.CompaniesList)}
          icon="company"
        />
        <DesktopSidebarNavItem
          as={Link}
          to={makeLink(paths.pages.GlobalStats, {})}
          label="Statistics"
          isSelected={isPathActive(paths.pages.GlobalStats)}
          icon="reporting"
        />
      </SidebarGroup>
    </DesktopSidebar>
  );
};

export const NOCMobileSidebar = ({ onClose }: { onClose: () => void }) => {
  const location = useLocation();
  const isPathActive = useIsPathActiveFn();

  const onClick = () => {
    onClose();
  };

  return (
    <MobileSidebarModal onClose={onClose}>
      <MobileSidebar>
        <MobileSidebarToggleContainer>
          <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
        </MobileSidebarToggleContainer>
        <SidebarGroup>
          <MobileSidebarNavItem
            as={Link}
            to={makeLink(paths.pages.ControllersList, {})}
            label="Controllers"
            isSelected={location.pathname === '/' || isPathActive(paths.pages.ControllerDetails)}
            icon="controller"
            onClick={onClick}
          />
          <MobileSidebarNavItem
            as={Link}
            to={makeLink(paths.pages.CompaniesList, {})}
            label="Companies"
            isSelected={isPathActive(paths.pages.CompaniesList)}
            icon="company"
            onClick={onClick}
          />
          <MobileSidebarNavItem
            as={Link}
            to={makeLink(paths.pages.GlobalStats, {})}
            label="Statistics"
            isSelected={isPathActive(paths.pages.GlobalStats)}
            icon="reporting"
            onClick={onClick}
          />
        </SidebarGroup>
      </MobileSidebar>
    </MobileSidebarModal>
  );
};
