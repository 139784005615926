import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidIncidentData } from './common/form_data';
import { createIncident } from '../../../../api/controllersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { getErrorMessage } from '../../../../errors/errors';
import { useCurrentTimezone } from '../../../../providers/CurrentTimezoneProvider';
import { styled } from '../../../../stitches';
import { checkDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../../utils/isDefined';
import { makeCloseDrawerLink, makeDrawerLink } from '../../../../utils/makeLink';
import { toIncidentCreateRequest, validIncidentData } from './common/form_data';
import { FormContent } from './common/FormContent';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents/new',
});

const StyledForm = styled('form', {
  display: 'contents',
});

export default function IncidentCreate() {
  const navigate = useNavigate();
  const timezone = useCurrentTimezone();

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.IncidentCreate),
  );

  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidIncidentData) => {
      const apiData = toIncidentCreateRequest(data, timezone);
      return createIncident(controllerName, apiData.incidentData);
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['controllers', controllerName, 'incidents']);
        navigate(
          makeDrawerLink(paths.drawers.IncidentDetail, {
            controllerName: result.controller,
            id: result.sid,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidIncidentData>
      initialValues={{
        start_time: '',
        end_time: '',
        notes: '',
        category: '',
      }}
      validationSchema={toFormikValidationSchema(validIncidentData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Create incident</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={getErrorMessage(createDrawerMutation.error)}
                />
              )}
              <FormContent />
            </DrawerContent>

            <DrawerFooter>
              <DrawerControls>
                <Button type="button" variant="secondary" as={Link} to={makeCloseDrawerLink()}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
