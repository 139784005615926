import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';

import Clients from '../../../components/Client/Clients';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { checkDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices/:deviceName/clients',
});

export default function ControllerDeviceClients() {
  const { controllerName, deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDeviceClients),
  );

  return <Clients controllerName={controllerName} deviceName={deviceName} />;
}
