import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { fetchCompanyUserJSON } from '../../../../api/usersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ResourceNotFoundError } from '../../../../errors/errors';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { formatFullName } from '../../../../utils/users';
import { UserNameAndEmailWidget } from './common/UserNameAndEmailWidget';
import { UserProfileAndPermissionsWidget } from './common/UserProfileAndPermissionsWidget';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users/:userSid',
});

export default function UserDetail() {
  const navigate = useNavigate();

  const { companyName, userSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UserDetail),
  );

  const user = useQuery(
    ['company', companyName, 'users', userSid],
    () => fetchCompanyUserJSON(companyName, userSid),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(user, new ResourceNotFoundError(`User ${userSid} not found`));

  const formattedName = formatFullName(user);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>User</DrawerTitle>
        <DrawerControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton
              icon="overflowVertical"
              arrangement="hidden-label"
              variant="secondary"
            >
              Actions
            </DropdownMenuTriggerButton>

            <DropdownMenuContent>
              <DropdownMenuItem
                icon="pencil"
                onSelect={() => {
                  navigate(
                    makeDrawerLink(paths.drawers.UserEdit, {
                      companyName,
                      userSid,
                    }),
                  );
                }}
              >
                Change role
              </DropdownMenuItem>
              <DropdownMenuItem
                icon="delete"
                onSelect={() => {
                  navigate(
                    makeDrawerLink(paths.drawers.UserRemove, {
                      companyName,
                      userSid,
                    }),
                  );
                }}
              >
                Remove user
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>

      <DrawerContent>
        {!formattedName && (
          <Alert
            icon="attention"
            heading="Pending details"
            copy="This user's profile information will sync when they first sign in using SSO."
          />
        )}
        <UserNameAndEmailWidget user={user} />
        <UserProfileAndPermissionsWidget user={user} />
      </DrawerContent>
    </Drawer>
  );
}
