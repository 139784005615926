import type { ReactNode } from 'react';
import {
  Alert,
  Badge,
  Body2,
  BodyMono2,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  Heading2,
  Link,
  List,
  ListItemHeader,
  ListTitle,
  Small2,
  Subheading2,
  Tab,
  Tabs,
} from '@meterup/metric';
import React, { useState } from 'react';

import type { DisplayableError } from '../errors/errors';
import { getErrorMessage, getErrorTitle, isDisplayableError } from '../errors/errors';
import { colors, styled } from '../stitches';
import { isDefined } from '../utils/isDefined';
import { CloseDrawerButton } from './CloseDrawerButton/CloseDrawerButton';
import { ListItemTableContainer } from './ListItemTableContainer';

const ErrorFallbackContainer = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  padding: '$20',
});

const DevelopmentErrorFallbackHeading = styled(Heading2, {
  color: colors['red-800'],
});

const CodeBlock = styled('pre', BodyMono2, {
  padding: '$8',
  backgroundColor: colors['gray-50'],
  overflow: 'auto',
  borderRadius: '$4',
});

/* eslint-disable react/no-unused-prop-types */
export interface ErrorFallbackProps {
  errorId?: string;
  error: Error;
  componentStack?: string | null;
  resetError?: () => void;
}
/* eslint-enable react/no-unused-prop-types */

const DevelopmentErrorFallback = ({
  error,
  componentStack,
}: {
  error: Error;
  componentStack?: string | null;
}) => {
  const [currentStack, setCurrentStack] = useState<'error' | 'component'>('error');
  return (
    <>
      <div>
        <DevelopmentErrorFallbackHeading>
          Uncaught error: {error.name}
        </DevelopmentErrorFallbackHeading>

        <Subheading2>{getErrorTitle(error)}</Subheading2>
        <Body2>{getErrorMessage(error)}</Body2>
      </div>
      <Tabs>
        <Tab active={currentStack === 'error'} onClick={() => setCurrentStack('error')}>
          JS Stack
        </Tab>
        <Tab active={currentStack === 'component'} onClick={() => setCurrentStack('component')}>
          Component Stack
        </Tab>
      </Tabs>
      {currentStack === 'error' ? (
        <CodeBlock>{error.stack}</CodeBlock>
      ) : (
        <CodeBlock>{componentStack}</CodeBlock>
      )}
      <Small2>This error would be logged to Sentry in staging or production.</Small2>
    </>
  );
};

export const UnexpectedErrorBoilerplate = ({ errorId }: { errorId?: string }) => (
  <>
    <p>
      An unexpected error has occurred. Please try refreshing the page. If the issue persists,
      please ping the <Link href="https://meterup.slack.com/archives/C01EW6GLQ21">#noc</Link>{' '}
      channel in Slack.
    </p>
    {isDefined(errorId) ? (
      <>
        <br />
        <p>
          Reference{' '}
          <Badge size="small" variant="brand">
            {errorId}
          </Badge>
        </p>
      </>
    ) : null}
  </>
);

export const GenericErrorFallback = ({
  error,
  errorId,
  componentStack,
  resetError,
}: ErrorFallbackProps) =>
  import.meta.env.NODE_ENV === 'pizza' ? (
    <ErrorFallbackContainer>
      <DevelopmentErrorFallback error={error} componentStack={componentStack} />
    </ErrorFallbackContainer>
  ) : (
    <ErrorFallbackContainer>
      {isDisplayableError(error) ? (
        <Alert
          icon="warning"
          variant="negative"
          heading={getErrorTitle(error)}
          copy={getErrorMessage(error)}
          trailingButtons={
            <Button variant="secondary" icon="arrowRotate" onClick={resetError}>
              Try again
            </Button>
          }
        />
      ) : (
        <Alert
          icon="warning"
          variant="negative"
          heading="Unexpected error"
          copy={<UnexpectedErrorBoilerplate errorId={errorId} />}
          trailingButtons={
            <Button variant="secondary" icon="arrowRotate" onClick={resetError}>
              Try again
            </Button>
          }
        />
      )}
    </ErrorFallbackContainer>
  );

const UnexpectedErrorFallbackDrawer = ({ componentStack, errorId, error }: ErrorFallbackProps) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Unexpected error</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      {import.meta.env.NODE_ENV === 'development' ? (
        <DevelopmentErrorFallback error={error} componentStack={componentStack} />
      ) : (
        <Alert
          variant="negative"
          heading="Unexpected error"
          copy={<UnexpectedErrorBoilerplate errorId={errorId} />}
        />
      )}
    </DrawerContent>
  </Drawer>
);

const getErrorDisplayInfo = (
  error: Error | (Error & DisplayableError),
  errorId: string | undefined,
) => {
  const isExpected = isDisplayableError(error);
  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error) ? (
    error.displayMessage
  ) : (
    <UnexpectedErrorBoilerplate errorId={errorId} />
  );

  return { title, message, isExpected };
};

const DisplayableErrorFallbackDrawer = ({
  error,
}: ErrorFallbackProps & { error: DisplayableError }) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>{error.displayTitle}</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Alert copy={error.displayMessage} />
    </DrawerContent>
  </Drawer>
);
export const ErrorFallbackDrawer = ({ error, ...rest }: ErrorFallbackProps) =>
  isDisplayableError(error) ? (
    <DisplayableErrorFallbackDrawer error={error} {...rest} />
  ) : (
    <UnexpectedErrorFallbackDrawer error={error} {...rest} />
  );

const Box = styled('div');

export const FatalErrorFallback = ({ error, errorId, componentStack }: ErrorFallbackProps) => {
  const display = getErrorDisplayInfo(error, errorId);

  return import.meta.env.NODE_ENV === 'development' ? (
    <ErrorFallbackContainer>
      <DevelopmentErrorFallback error={error} componentStack={componentStack} />
    </ErrorFallbackContainer>
  ) : (
    <Box
      css={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box css={{ vStack: '$32', maxWidth: 400, alignItems: 'stretch' }}>
        <Box css={{ vStack: '$20', alignItems: 'flex-start' }}>
          <Box css={{ vStack: '$4', alignItems: 'flex-start' }}>
            <Heading2>{display.title}</Heading2>
          </Box>
          <Body2>{display.message}</Body2>
        </Box>
      </Box>
    </Box>
  );
};

export const ErrorFallbackWidget = ({
  title,
  errorId,
  error,
  resetError,
}: ErrorFallbackProps & { title: ReactNode }) => {
  const display = getErrorDisplayInfo(error, errorId);

  return (
    <List>
      <ListItemHeader>
        <ListTitle>{title}</ListTitle>
      </ListItemHeader>

      <ListItemTableContainer>
        <Alert
          icon="warning"
          variant="negative"
          cornerStyle="square"
          heading={display.title}
          copy={display.message}
          trailingButtons={
            <Button variant="secondary" icon="arrowRotate" onClick={resetError}>
              Try again
            </Button>
          }
        />
      </ListItemTableContainer>
    </List>
  );
};
