import { useQuery } from 'react-query';

import type { ControllerVersion } from '../utils/controllers';
import { fetchControllerJSON } from '../api/controllersApi';
import { getControllerVersion } from '../utils/controllers';
import { isDefined } from '../utils/isDefined';

const UNKNOWN_CONTROLLER_VERSION = {
  isCOS: false,
  isLegacy: false,
  versionString: '',
};

export function useControllerVerion(controllerName: string): ControllerVersion {
  const info = useQuery(
    ['controllers', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: false },
  ).data;

  return isDefined(info) ? getControllerVersion(info) : UNKNOWN_CONTROLLER_VERSION;
}
