import { BandWithIcon } from '@meterup/metric';
import React from 'react';
import { match } from 'ts-pattern';

import { styled } from '../stitches';
import { OnlineOfflineDraftBadge } from './Network/badges';

const Container = styled('div', { position: 'relative', display: 'inline-flex' });

const BadgeRegion = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: -4,
  left: 0,
  right: 0,
});

export const DeviceStatusIcon = ({ value }: { value: 'online' | 'offline' | string }) => {
  const variant = match(value)
    .with('online', () => 'positive' as const)
    .with('offline', () => 'negative' as const)
    .with('draft', () => 'alternative' as const)
    .otherwise(() => 'neutral' as const);

  return (
    <Container>
      <BandWithIcon icon="ap" size="medium" variant={variant} />
      <BadgeRegion>
        <OnlineOfflineDraftBadge value={value} />
      </BadgeRegion>
    </Container>
  );
};
