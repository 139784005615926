import type { BadgeProps } from '@meterup/metric';
import { Badge } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { match } from 'ts-pattern';

import { isDefined } from '../../utils/isDefined';
import { formatISPProduct } from '../../utils/isp_product';
import { formatISPStatus } from '../../utils/isp_status';

// Value obtained via careful measurement of Slack message from Joe. Signal
// strength greater than this value is considered "good", otherwise "bad".
const SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM = -74;

type CurriedBadgeProps = Partial<BadgeProps>;

export const PositiveBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'positive',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);

export const NegativeBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'negative',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);

export const NeutralBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'neutral',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);

export const AlternativeBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'alternative',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);

export const OnlineBadge = ({ children = 'Online', ...props }: CurriedBadgeProps) => (
  <PositiveBadge {...props}>{children}</PositiveBadge>
);

export const OfflineBadge = ({ children = 'Offline', ...props }: CurriedBadgeProps) => (
  <NegativeBadge {...props}>{children}</NegativeBadge>
);

export const DraftBadge = ({ children = 'Draft', ...props }: CurriedBadgeProps) => (
  <AlternativeBadge {...props}>{children}</AlternativeBadge>
);

export const UnknownBadge = ({ children = 'Unknown', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const PendingBadge = ({ children = 'Pending', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const WiredBadge = ({ children = 'Wired', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const WirelessBadge = ({ children = 'Wireless', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const DisabledBadge = ({ children = 'Disabled', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const EnabledBadge = ({ children = 'Enabled', ...props }: CurriedBadgeProps) => (
  <PositiveBadge {...props}>{children}</PositiveBadge>
);

export const ProvisionedBadge = ({ children = 'Provisioned', ...props }: CurriedBadgeProps) => (
  <AlternativeBadge {...props}>{children}</AlternativeBadge>
);

export const InstalledPrimaryBadge = ({ children = 'Primary', ...props }: CurriedBadgeProps) => (
  <PositiveBadge {...props}>{children}</PositiveBadge>
);

export const InstalledBackupBadge = ({ children = 'Backup', ...props }: CurriedBadgeProps) => (
  <AlternativeBadge {...props}>{children}</AlternativeBadge>
);

export const InactiveBadge = ({ children = 'Inactive', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const UninstalledBadge = ({ children = 'Uninstalled', ...props }: CurriedBadgeProps) => (
  <NeutralBadge {...props}>{children}</NeutralBadge>
);

export const OnlineOfflineStatusBadge = ({
  value,
  ...props
}: { value: 'online' | 'offline' | string } & CurriedBadgeProps) =>
  match(value)
    .with('online', () => <OnlineBadge {...props} />)
    .with('offline', () => <OfflineBadge {...props} />)
    .otherwise(() => <UnknownBadge {...props} />);

export const LifecycleStatusBadge = ({
  value,
  ...props
}: { value: api.LifecycleStatus } & CurriedBadgeProps) =>
  match(value)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_PROVISIONED, () => <ProvisionedBadge {...props} />)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY, () => (
      <InstalledPrimaryBadge {...props} />
    ))
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP, () => (
      <InstalledBackupBadge {...props} />
    ))
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_UNINSTALLED, () => <UninstalledBadge {...props} />)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INACTIVE, () => <InactiveBadge {...props} />)
    .otherwise(() => <UnknownBadge {...props} />);

export const OnlineOfflineDraftBadge = ({
  value,
  ...props
}: {
  value: 'online' | 'offline' | 'draft' | string;
} & CurriedBadgeProps) =>
  match(value)
    .with('online', () => <OnlineBadge {...props} />)
    .with('offline', () => <OfflineBadge {...props} />)
    .with('draft', () => <DraftBadge {...props} />)
    .otherwise((unknownStatus) => <UnknownBadge {...props}>{unknownStatus}</UnknownBadge>);

export const WiredWirelessBadge = ({
  value,
  ...props
}: {
  value: 'wired' | 'wireless' | string;
} & CurriedBadgeProps) =>
  match(value)
    .with('wired', () => <WiredBadge {...props} />)
    .with('wireless', () => <WirelessBadge {...props} />)
    .otherwise((unknownStatus) => <UnknownBadge {...props}>{unknownStatus}</UnknownBadge>);

export const SignalStrengthBadge = ({
  value,
  ...props
}: {
  value: number | null | undefined;
} & CurriedBadgeProps) =>
  match(value)
    .when(
      (v) => isDefined(v) && v >= SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM,
      () => <PositiveBadge {...props}>{value} dBm</PositiveBadge>,
    )
    .when(
      (v) => isDefined(v) && v < SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM,
      () => <NegativeBadge {...props}>{value} dBm</NegativeBadge>,
    )
    .otherwise(() => null);

export const UserRoleBadge = ({
  value,
  ends = 'card',
  ...props
}: { value: api.CompanyMembershipRole } & CurriedBadgeProps) =>
  match(value)
    .with(api.CompanyMembershipRole.admin, () => (
      <NegativeBadge ends={ends} {...props}>
        Admin
      </NegativeBadge>
    ))
    .with(api.CompanyMembershipRole.member, () => (
      <NeutralBadge ends={ends} {...props}>
        Member
      </NeutralBadge>
    ))
    .with(api.CompanyMembershipRole.guest, () => (
      <NeutralBadge ends={ends} {...props}>
        Guest
      </NeutralBadge>
    ))
    .with(api.CompanyMembershipRole.unknown, () => (
      <NeutralBadge ends={ends} {...props}>
        Unknown
      </NeutralBadge>
    ))
    .exhaustive();

export const ServicePlanProductBadge = ({ value }: { value: api.ISPProduct }) => (
  <Badge size="small">{formatISPProduct(value)}</Badge>
);

export const ServicePlanStatusBadge = ({ value }: { value: api.ISPStatus }) => (
  <Badge size="small">{formatISPStatus(value)}</Badge>
);

export const NetworkInterfaceBadge = ({ value }: { value?: string }) => (
  <Badge size="small">{value ?? 'None'}</Badge>
);

export const CountBadge = ({
  value,
  arrangement = 'leading-icon',
  icon,
  ...props
}: {
  value?: number;
} & CurriedBadgeProps) =>
  value === 0 ? (
    <NeutralBadge {...props} arrangement={arrangement}>
      {value ?? 0}
    </NeutralBadge>
  ) : (
    <AlternativeBadge {...props} icon={icon} arrangement={arrangement}>
      {value ?? 'None'}
    </AlternativeBadge>
  );

export const OnlineOfflineClientCountBadge = ({
  value,
  arrangement = 'leading-icon',
  icon = 'client',
  ...props
}: { value?: number } & CurriedBadgeProps) =>
  value === 0 ? (
    <NeutralBadge {...props} arrangement={arrangement}>
      {value ?? 0}
    </NeutralBadge>
  ) : (
    <PositiveBadge {...props} arrangement={arrangement} icon={icon}>
      {value ?? 'None'}
    </PositiveBadge>
  );
