import React from 'react';

import { colors, darkThemeSelector, keyframes, styled } from '../../stitches.config';

const skeletonRipple = keyframes({
  '0%, 100%': { opacity: '0.1' },
  '50%': { opacity: '0.2' },
});

const skeletonRippleDark = keyframes({
  '0%, 100%': { opacity: '0.1' },
  '50%': { opacity: '0.2' },
});

const Base = styled('div', {
  backgroundColor: colors['gray-400'],
  animation: `${skeletonRipple} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
  [darkThemeSelector]: {
    backgroundColor: colors['gray-300'],
    animation: `${skeletonRippleDark} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
  },
});

// Display types
export const SkeletonDisplay = ['block', 'inline-block'] as const;

export interface SkeletonProps {
  /**
   * Set the display type of the box modal.
   */
  display?: typeof SkeletonDisplay[number];
  /**
   * A string to represent the height as a CSS value.
   */
  height: string;
  /**
   * Set the border radius of the component.
   */
  radius?: number;
  /**
   * A string to represent the width as a CSS value.
   */
  width?: string;
}

export const Skeleton: React.FunctionComponent<SkeletonProps> = ({
  display,
  height = '40px',
  radius,
  width = '40px',
}) => (
  <Base
    style={{
      width,
      height,
      display,
      borderRadius: radius,
    }}
  />
);

export default Skeleton;
