import type { AxiosError } from 'axios';
import axios from 'axios';

import { API_BASE_URL } from '../constants';
import { createLegacyProtoClient } from './createLegacyProtoClient';

export const axiosInstanceProto = axios.create({
  withCredentials: true,
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/x-protobuf',
    Accept: 'application/x-protobuf',
  },
  baseURL: API_BASE_URL,
});

export const axiosInstanceJSON = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
});

export const legacyProtoClient = createLegacyProtoClient(axiosInstanceProto);

export function isAxiosError(value: unknown): value is AxiosError {
  return axios.isAxiosError(value);
}
